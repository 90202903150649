<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="700">
    <v-card>
      <v-card-title v-if="title" class="headline">{{ title }}</v-card-title>
      <v-card-text>
        <v-form>
          <template v-for="(row, idx) in fields">
            <v-text-field
              v-bind:key="idx"
              v-model="row.value"
              :label="row.text"
            ></v-text-field>
          </template>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="error" v-if="!!id && !!urlDel" @click="del">
          {{ $t('all.delete') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="$close(false)"> {{ $t('all.close') }} </v-btn>
        <v-btn color="primary" dark @click="save()">
          {{ $t('all.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import confirm from './confirm.vue'
import { create } from 'vue-modal-dialogs'
const confirmBox = create(confirm)
export default {
  props: {
    title: String,
    titleDel: String,
    delMsg: String,
    id: String,
    urlSave: String,
    urlDel: String,
    type: String,
    fields: Array,
    prm: Array, //параметры, которые должны быть переданы в запрос сохранения
    dialog: {
      default: true,
    },
  },
  methods: {
    parseForm(e) {
      var ret = {}
      console.log(e)
      e.forEach(function (entry) {
        ret[entry.name] = entry.value
      })
      console.log('this.prm', this.prm)
      if (this.prm) {
        this.prm.forEach(function (entry) {
          ret[entry.name] = entry.value
        })
      }
      ret.id = this.id
      return ret
    },
    save: function () {
      var t = this
      t.$store.commit('loadingStartShow', true)
      this.$http
        .post(this.$store.state.apiUrl + this.urlSave, t.parseForm(t.fields))
        .then(
          (response) => {
            t.$store.commit('loadingStartShow', false)
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: t.$t(response.body.msg),
            })
            if (!response.body.err) {
              this.$close(true)
            }
          },
          (err) => {
            console.log(err)
          }
        )
    },
    del: function () {
      var t = this
      confirmBox({
        text: t.delMsg, //'Вы хотите удалить запись ?',
        type: 'delete',
        title: t.titleDel, //'Подтверждение удаления',
      })
        .transition()
        .then((response) => {
          if (response) {
            t.$store.commit('loadingStartShow', true)
            this.$http
              .post(this.$store.state.apiUrl + this.urlDel, { id: t.id })
              .then(
                (response) => {
                  t.$store.commit('loadingStartShow', false)
                  t.$store.dispatch('setMessage', {
                    type: response.body.err == 1 ? 'error' : 'success',
                    message: t.$t(response.body.msg),
                  })
                  if (!response.body.err) {
                    t.$close(true)
                  }
                },
                (err) => {
                  console.log(err)
                }
              )
          }
        })
    },
  },
}
</script>

<style></style>
