<template>
  <v-dialog v-model="dialog">
    <v-card>
      <v-card-title v-if="title" class="headline">{{ title }}</v-card-title>
      <v-card-text>
        <v-alert outlined type="error" border="left" color="error">
          {{ text }}
        </v-alert>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn text @click="$close(false)"> {{ $t('all.close') }} </v-btn>
        <v-btn v-if="type == 'delete'" color="error" dark @click="$close(true)">
          {{ $t('all.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: String,
    text: String,
    type: String,
    dialog: {
      default: true,
    },
  },
}
</script>

<style></style>
